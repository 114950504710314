import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Axios from 'axios';
import { BURNETT_API_ENDPOINT } from './config';
import Header from './components/header';
import HomeContainer from './containers/home';
import CompeteContainer from './containers/compete';
import CompareContainer from './containers/compare';
import CompetitionContainer from './containers/competition';
import EditCompetitionContainer from './containers/editCompetition';
import InviteCompetitionContainer from './containers/inviteCompetition';
import ManageSubmissions from './containers/manageSubmissions';
import EditSubmission from './containers/editSubmission';
import SubmissionContainer from './containers/submission';

import ProfileContainer from './containers/profile';
import ProfileFormContainer from './containers/profileForm';
import PublicProfileContainer from './containers/publicProfile';

import "react-toastify/dist/ReactToastify.css";
import './app.scss';
import { use } from 'react';


const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  const currentYear = new Date().getFullYear();
  const { isLoading, isAuthenticated, error, user, loginWithRedirect, logout, getIdTokenClaims } = useAuth0();
  const [showRoutes, setShowRoutes]= useState(false);

  const syncUserSession = async () => {
    try {
      const claims = await getIdTokenClaims();
      const idToken = claims.__raw;

      await Axios.post(`${BURNETT_API_ENDPOINT}/sync-user-session`, {}, {
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json"
        }
      });

    } catch (error) {
      console.error('Error syncing user session:', error);
      toast.error('Failed to sync user session.');
    }
  };

  useEffect(() => {
    if (!user || !isAuthenticated) return;
    syncUserSession();
  }, [user, isAuthenticated]);

  if (isLoading) {
    return <div></div>;
  }
  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  const isLoadingComplete = () => {
    setShowRoutes(true);
  }

  return (
    <BrowserRouter>
      <ScrollToTop />
      <ToastContainer position="top-left" autoClose={10000} />
      <div className="app-container">

          <Header
            logout={logout}
            loginWithRedirect={loginWithRedirect}
            isAuthenticated={isAuthenticated}
            user={user}
            getIdTokenClaims={getIdTokenClaims}
            isLoadingComplete={isLoadingComplete}
          />
        {isAuthenticated && showRoutes ? (
          <Routes>
            <Route index path="/" element={<HomeContainer user={user} />} />
            <Route index path="/compete" element={<CompeteContainer />} />
            <Route index path="/compare" element={<CompareContainer />} />
            <Route index path="/edit-profile" element={<ProfileContainer />} />
            <Route index path="/edit-profile-form" element={<ProfileFormContainer />} />
            <Route index path="/profile/:publicProfileId" element={<PublicProfileContainer />} />
            <Route path="/competition/:competitionId" element={<CompetitionContainer />} />
            <Route path="/edit/:competitionId" element={<EditCompetitionContainer />} />
            <Route path="/invite/:competitionId" element={<InviteCompetitionContainer />} />
            <Route path="/competition/:competitionId/manage-submission" element={<ManageSubmissions />} />
            <Route path="/competition/:competitionId/manage-submission/:submissionId" element={<EditSubmission />} />
            <Route path="/competition/:competitionId/submission/:submissionId" element={<SubmissionContainer />} />
          </Routes>
        ) : (
          <div></div>
        )}
        <div className="app-footer" style={ window.location.pathname === '/edit-profile-form' ? {display: 'none'} : {}}>
          <div className="footer-links">
            <ul className="left-links">
              <li>
                <a href="mailto:support@longjump.zendesk.com">Support</a>
              </li>
            </ul>
            <span>&copy;{currentYear}, LongJump AI, All Rights Reserverd</span>
            <ul>
              <li className="show-mobile">
                <a href="mailto:support@longjump.zendesk.com">Support</a>
              </li>
              <li className="show-mobile"> | </li>
              <li>
                <a href="https://longjump.ai/terms.html" target="_blank" rel="noopener noreferrer">
                  Terms
                </a>
              </li>
              <li> | </li>
              <li>
                <a href="https://longjump.ai/privacy.html" target="_blank" rel="noopener noreferrer">
                  Privacy
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
