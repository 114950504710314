import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { toast } from "react-toastify";
import moment from 'moment';
import Popup from '../../components/generic/popup';
import UserCard from '../../components/generic/userCard';
import CompetitionStatus from '../../components/competition/competitionStatus';
import Button from '../../components/generic/button';
import MultiSelect from '../../components/generic/multiSelect';
import ManageTeamList from '../../components/generic/manageTeamList';
import ManageKeyValuePairList from '../../components/generic/manageKeyValuePairList';
import UploadSubmissionFiles from '../../components/generic/uploadSubmissionFiles';
import BackButton from '../../components/generic/backButton';
import { BURNETT_API_ENDPOINT } from '../../config';
import './edit-submission.scss';

function EditSubmission() {
  // Subscription check hook: null means "not checked yet"
  const [isSubscriber, setIsSubscriber] = useState(null);
  useEffect(() => {
    const subStatus = localStorage.getItem('sub_status');
    setIsSubscriber(!!(subStatus && (subStatus.includes("active") || subStatus.includes("trialing"))));
  }, []);  

  // Other hooks (always declared in the same order)
  const navigate = useNavigate();
  const { competitionId, submissionId } = useParams();
  const { user, getIdTokenClaims } = useAuth0();
  const [loading, setLoading] = useState(true);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [error, setError] = useState(null);
  const [userSessionScope, setUserSessionScope] = useState("");
  const [competitionName, setCompetitionName] = useState("");
  const [submissionName, setSubmissionName] = useState("");
  const [submissionDesc, setSubmissionDesc] = useState("");
  const [submissionStatus, setSubmissionStatus] = useState("");
  const [submissionCreatorId, setSubmissionCreatorId] = useState("");
  const [activeUserEmail, setActiveUserEmail] = useState("");
  const [videoUrl, setVideoUrl] = useState(null);
  const [muxPlaybackId, setMuxPlaybackId] = useState(null);
  const [teamList, setTeamList] = useState([]);
  const [linkList, setLinkList] = useState([]);
  const [videoUploadProgress, setVideoUploadProgress] = useState(null);
  const [competitionUsers, setCompetitionUsers] = useState([]);
  const [competitionStartDate, setCompetitionStartDate] = useState(null);
  const [competitionEndDate, setCompetitionEndDate] = useState(null);
  const [competitionDeadlineDate, setCompetitionDeadlineDate] = useState(null);
  const [compEnded, setCompEnded] = useState(false);
  const [activeCategorySelections, setActiveCategorySelections] = useState([]);
  const [categoryOptions] = useState([
    'Technology',
    'Finance',
    'Healthcare',
    'Education',
    'Energy',
    'Manufacturing',
    'Retail',
    'Ecommerce',
    'Transportation',
    'Hospitality & Leisure',
    'Fashion & Apparel',
    'Beauty & Personal Care',
    'Construction',
    'Media & Entertainment',
    'Agriculture',
    'Food & Beverage',
    'Biotech',
    'Security',
    'Professional Services'
  ]);
  const [competitionSubmission, setCompetitionSubmission] = useState(null);
  const [confirmDeletePopupActive, setConfirmDeletePopupActive] = useState(false);
  const [manageTeamPopupActive, setManageTeamPopupActive] = useState(false);
  const [manageLinksPopupActive, setManageLinksPopupActive] = useState(false);

  const findUserTypeByEmail = (users = [], email) => {
    for (let i = 0; i < users.length; i++) {
      if (users[i].email === email) {
        return users[i].type;
      }
    }
    return null;
  };

  const fetchCompetitionData = async () => {
    setLoading(true);
    try {
      const claims = await getIdTokenClaims();
      const idToken = claims.__raw;
      const response = await axios.get(`${BURNETT_API_ENDPOINT}/get-competition?competition_id=${competitionId}`, {
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json"
        }
      });
      const data = response.data;
      const userSessionEmail = user.email;
      const permissionScope = findUserTypeByEmail(data.users, userSessionEmail);
      setUserSessionScope(permissionScope);
      setActiveUserEmail(user.email);
      setCompetitionName(data.competition_name);
      setCompetitionUsers(data.users);
      fetchSubmissionData();
      setCompetitionStartDate(data.competition_start_date);
      setCompetitionDeadlineDate(data.competition_deadline_date);
      setCompetitionEndDate(data.competition_end_date);
    } catch (err) {
      setError(err.message);
    }
  };

  const fetchSubmissionData = async () => {
    setLoading(true);
    try {
      const claims = await getIdTokenClaims();
      const idToken = claims.__raw;
      const response = await axios.get(
        `${BURNETT_API_ENDPOINT}/get-competition-submissions?competition_id=${competitionId}&submission_id=${submissionId}`,
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json"
          }
        }
      );
      const data = response.data;
      const { data: submissionData } = data;
      if (submissionData && submissionData.length > 0) setCompetitionSubmission(submissionData[0]);
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!competitionEndDate) return;
    const now = moment();
    const endDate = moment(competitionEndDate);
    setCompEnded(now.isAfter(endDate));
  }, [competitionEndDate]);

  useEffect(() => {
    if (!competitionSubmission) return;
    const {
      submission_name,
      submission_desc,
      status,
      user_id,
      mux_playback_id,
      submission_categories = [],
      submission_team = [],
      submission_links = [],
      video_url,
    } = competitionSubmission;
    setSubmissionName(submission_name);
    setSubmissionDesc(submission_desc);
    setSubmissionStatus(status);
    setSubmissionCreatorId(user_id);
    setTeamList(submission_team);
    setLinkList(submission_links);
    setActiveCategorySelections(submission_categories);
    setVideoUrl(video_url);
    setMuxPlaybackId(mux_playback_id);
  }, [competitionSubmission]);

  // Only fetch competition data if the user is an active subscriber.
  useEffect(() => {
    if (isSubscriber !== true) return;
    fetchCompetitionData();
  }, [competitionId, getIdTokenClaims, isSubscriber]);

  const toggleDeleteSubmissionClick = async () => {
    setConfirmDeletePopupActive(!confirmDeletePopupActive);
  };

  const toggleManageTeamClick = async () => {
    setManageTeamPopupActive(!manageTeamPopupActive);
  };

  const toggleManageLinksClick = async () => {
    setManageLinksPopupActive(!manageLinksPopupActive);
  };

  const confirmDeleteSubmission = async () => {
    if (isDeleteLoading) return;
    setIsDeleteLoading(true);
    try {
      const claims = await getIdTokenClaims();
      const idToken = claims.__raw;
      const deleteParams = {
        competition_id: competitionId,
        submission_id: submissionId
      };
      await axios.post(`${BURNETT_API_ENDPOINT}/delete-competition-submission`, deleteParams, {
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json"
        }
      });
      toast.success('Submission deleted');
      navigate(`/competition/${competitionId}/manage-submission`);
    } catch (err) {
      setError(err.message);
      setIsDeleteLoading(false);
      toast.error('Error deleting submission');
    }
  };

  const confirmSaveSubmission = async (togglePublished) => {
    const currentTime = moment();
    const deadlineDate = competitionDeadlineDate ? moment(competitionDeadlineDate) : moment(competitionEndDate);
    if (currentTime.isAfter(deadlineDate)) {
      toast.error('The deadline has passed. Cannot edit this submission.');
      return;
    }
    if (compEnded) {
      toast.warning('The competition has ended. No further changes are allowed.');
      return;
    }
    if (!teamList || teamList.length === 0) {
      toast.error('A submission needs at least one teammate');
      return;
    }
    if (isSaveLoading) return;
    setIsSaveLoading(true);
    try {
      const claims = await getIdTokenClaims();
      const idToken = claims.__raw;
      const saveParams = {
        competition_id: competitionId,
        submission_id: submissionId,
        submission_name: submissionName,
        submission_desc: submissionDesc,
        submission_categories: activeCategorySelections,
        submission_team: teamList,
        submission_links: linkList,
        status: togglePublished
          ? (submissionStatus === "PUBLISHED" ? "DRAFT" : "PUBLISHED")
          : submissionStatus,
      };
      await axios.post(`${BURNETT_API_ENDPOINT}/update-competition-submission`, saveParams, {
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json"
        }
      });
      toast.success('Submission saved');
      setIsSaveLoading(false);
      fetchSubmissionData();
    } catch (err) {
      setError(err.message);
      setIsSaveLoading(false);
      toast.error('Error saving submission');
    }
  };

  const handleSubmissionNameInputChange = (e) => {
    setSubmissionName(e.target.value);
  };

  const handleSubmissionDescInputChange = (e) => {
    setSubmissionDesc(e.target.value);
  };

  const handleSelectionChange = (selectedOptions) => {
    setActiveCategorySelections(selectedOptions);
  };

  const updateTeamList = (selectedOptions) => {
    toggleManageTeamClick();
    setTeamList(selectedOptions);
  };

  const updateLinkList = (selectedOptions) => {
    toggleManageLinksClick();
    setLinkList(selectedOptions);
  };

  const updateUploadProgress = (uploadProgress) => {
    setVideoUploadProgress(uploadProgress);
  };

  // Conditional rendering: wait until the subscription check is complete.
  if (isSubscriber === null) return null;
  if (!isSubscriber) {
    return (
      <div className="edit-submission-container">
        <h3>Join the Competition as a LongJump Subscriber</h3>
        <p>
          To participate in this competition, you must be a LongJump subscriber.
          Please subscribe to unlock your entry!
        </p>
        <br />
        <Button
          text="Subscribe"
          onClick={() => window.location.href = 'https://id.longjump.ai/plans'}
          className="primary"
        />
      </div>
    );
  }

  // Main UI for active subscribers
  return (
    <div className="edit-submission-container" style={videoUploadProgress ? { pointerEvents: "none" } : {}}>
      {manageTeamPopupActive && (
        <Popup isOpen={manageTeamPopupActive} onClose={toggleManageTeamClick}>
          <h2>Edit Teammates</h2>
          <p>Manage submission teammates.</p>
          <ManageTeamList activeTeamList={teamList} activeUserEmail={activeUserEmail} onClose={updateTeamList} />
        </Popup>
      )}

      {manageLinksPopupActive && (
        <Popup isOpen={manageLinksPopupActive} onClose={toggleManageLinksClick}>
          <h2>Edit Links</h2>
          <p>Include links to relevant social media profiles, files and websites.</p>
          <ManageKeyValuePairList activePairList={linkList} onClose={updateLinkList} />
        </Popup>
      )}

      {confirmDeletePopupActive && (
        <Popup isOpen={confirmDeletePopupActive} onClose={toggleDeleteSubmissionClick}>
          <h2>Are you sure you want to delete this submission?</h2>
          <p>All submission data will be permanently deleted.</p>
          <Button
            text={isDeleteLoading ? "Loading" : "Confirm Delete Submission"}
            onClick={confirmDeleteSubmission}
            className="danger delete-button"
          />
        </Popup>
      )}

      {!loading ? (
        <>
          <BackButton />
          {competitionName && <h3>Edit your team submission for:</h3>}
          <h1>{competitionName}</h1>
          {competitionStartDate && competitionEndDate && (
            <CompetitionStatus startDate={competitionStartDate} endDate={competitionEndDate} deadline={competitionDeadlineDate} />
          )}
          <div className="edit-submission-content">
            <div className="edit-submission-content-section section-left">
              <UploadSubmissionFiles
                competitionId={competitionId}
                submissionId={submissionId}
                existingVideoUrl={videoUrl}
                muxPlaybackId={muxPlaybackId}
                fetchSubmissionData={() => confirmSaveSubmission()}
                updateUploadProgress={updateUploadProgress}
              />
            </div>
            <div className="edit-submission-content-section section-right" style={videoUploadProgress ? { opacity: 0.5 } : {}}>
              <b>Team Name</b>
              <input
                type="text"
                name="key"
                value={submissionName}
                placeholder="Submission Team Name"
                spellCheck={false}
                onChange={handleSubmissionNameInputChange}
              />
              <b>Description</b>
              <textarea
                type="text"
                name="key"
                value={submissionDesc}
                placeholder="Submission Description"
                spellCheck={false}
                onChange={handleSubmissionDescInputChange}
              />
              <div className="submission-category-select">
                <b>Category</b>
                <MultiSelect
                  options={categoryOptions}
                  activeSelections={activeCategorySelections}
                  onSelectionChange={handleSelectionChange}
                />
              </div>
              <div className="submission-links">
                <b>Links</b>
                <ul className="link-list">
                  {linkList && linkList.length > 0 && linkList.map((linkItem, i) => {
                    const { key, value } = linkItem;
                    return (
                      <li key={i}>
                        <div className="link-key">{key}:</div>
                        <a href={value} className="link-value" target="_blank" rel="noopener noreferrer">{value}</a>
                      </li>
                    );
                  })}
                </ul>
                <Button text="Edit Links" onClick={toggleManageLinksClick} className="primary" />
              </div>

              <div className="submission-team">
                <b>Teammates</b>
                <ul className="team-list">
                  {teamList && teamList.length > 0 && teamList.map((teamMate) => {
                    const matchingUser = competitionUsers.find(u => u.email === teamMate);
                    const userPathId = matchingUser ? matchingUser.user_id : "no-profile";
                    return <UserCard key={teamMate} competitionId={competitionId} userId={userPathId} userEmail={teamMate} isRow={true} />;
                  })}
                </ul>
                <Button text="Edit Teammates" onClick={toggleManageTeamClick} className="primary" />
              </div>

              <div className="edit-submission-footer">
                <Button text="Delete" onClick={toggleDeleteSubmissionClick} className="secondary" />
                <Button text="Save" onClick={() => confirmSaveSubmission()} className="secondary" />
                <Button
                  text={submissionStatus === "DRAFT" ? "Publish" : "Un-Publish"}
                  onClick={() => confirmSaveSubmission(true)}
                  className="primary"
                />
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
}

export default EditSubmission;
