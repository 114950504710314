import React, { useEffect, useState, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { toast } from "react-toastify";
import Axios from 'axios';
import DatePicker from 'react-datepicker';
import { BURNETT_API_ENDPOINT } from '../../config';
import Button from '../../components/generic/button';
import Popup from '../../components/generic/popup';
import ListCompetitions from '../../components/generic/listCompetitions';
import CreatePrizeList from '../../components/generic/createPrizeList';
import './home.scss';
import 'react-datepicker/dist/react-datepicker.css';

function Home({ user }) {
  const { getIdTokenClaims } = useAuth0();
  const [isLoading, setIsLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(null);
  const [userName, setUserName] = useState("");
  const [createCompetitionPopupActive, setCreateCompetitionPopupActive] = useState(false);
  const [competitionName, setCompetitionName] = useState('');
  const [competitionDescription, setCompetitionDescription] = useState('');
  const [competitionRules, setCompetitionRules] = useState('');
  const [competitionPrizes, setCompetitionPrizes] = useState([]);
  const [videoFile, setVideoFile] = useState(null);
  const fileInputRef = useRef(null);
  const [startDate, setStartDate] = useState(null);
  const [deadlineDate, setDeadlineDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isTenantAdmin, setIsTenantAdmin] = useState(false);
  const [isOrganizerSub, setIsOrganizerSub] = useState(false);

  useEffect(() => {
    if (!user || isLoading) return;
    const customFirstName = localStorage.getItem('custom_first_name') || "";
    setUserName(customFirstName);
    const tenantAdmin = localStorage.getItem('tenant_admin') === 'true';
    setIsTenantAdmin(tenantAdmin);

    // Check localStorage for subscription status and type.
    const subStatus = localStorage.getItem('sub_status') || "";
    const subType = localStorage.getItem('sub_type') || "";
    if ((subStatus.includes("active") || subStatus.includes("trialing")) && subType === "ORGANIZER_SUB") {
      setIsOrganizerSub(true);
    } else {
      setIsOrganizerSub(false);
    }
  }, [user, isLoading]);

  const toggleCreateCompetitionPopup = () => {
    setCreateCompetitionPopupActive(!createCompetitionPopupActive);
  };

  const handleCompetitionNameInputChange = (e) => {
    setCompetitionName(e.target.value);
  };

  const handleCompetitionDescriptionInputChange = (e) => {
    setCompetitionDescription(e.target.value);
  };

  const handleCompetitionRulesInputChange = (e) => {
    setCompetitionRules(e.target.value);
  };

  const handleCreateCompetitionSubmit = async (e) => {
    e.preventDefault();

    if (!competitionName || competitionName.length <= 3) {
      toast.warning("Competition names need to be greater than three characters.");
      return;
    }

    if (!videoFile) {
      toast.warning("Please select a video to upload with your competition.");
      return;
    }

    if (!startDate || !endDate || !deadlineDate) {
      toast.warning("Please select competition start, submission deadline and end dates.");
      return;
    }
    
    if (isLoading) return;
    setIsLoading(true);

    const params = {
      name: competitionName,
      description: competitionDescription,
      rules: competitionRules,
      video_name: videoFile.name,
      video_type: videoFile.type,
      start_date: startDate,
      deadline_date: deadlineDate,
      end_date: endDate,
      prizes: competitionPrizes
    };

    try {
      const claims = await getIdTokenClaims();
      const idToken = claims.__raw;

      const createResult = await Axios.post(`${BURNETT_API_ENDPOINT}/create-competition`, params, {
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json"
        }
      });

      if (videoFile) {
        const { data } = createResult;
        const { presigned_url: preSignedUrl } = data;
        await Axios.put(preSignedUrl, videoFile, {
          headers: {
            "Content-Type": videoFile.type
          },
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            const percentCompleted = Math.round((loaded * 100) / total);
            setUploadProgress(percentCompleted);
          }
        });
      }

      setIsLoading(false);
      toggleCreateCompetitionPopup();
      setVideoFile(null);
      setUploadProgress(null);
      setCompetitionName("");
      setCompetitionDescription("");
      toast.success('Competition successfully created.');
    } catch (error) {
      console.error(error);
      toast.error('Failed to create competition.');
      setIsLoading(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files && files[0]) {
      setVideoFile(files[0]);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setVideoFile(file);
    }
  };

  const handleDropZoneClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handlePrizesChange = (prizes) => {
    setCompetitionPrizes(prizes);
  };

  return (
    <div className="home-container">
      {(isTenantAdmin || isOrganizerSub) && (
        <div className="create-cta-container" style={isLoading ? { pointerEvents: 'none' } : {}}>
          <div className="trophy-icon" />
          <h2>Create a competition with LongJump!</h2>
          <Button text="Create Competition" onClick={toggleCreateCompetitionPopup} className="primary" />
          
          {createCompetitionPopupActive && (
            <Popup isOpen={createCompetitionPopupActive} onClose={toggleCreateCompetitionPopup}>
              <h2 style={{ marginRight: 0 }}>Create a competition</h2>
              <p>Add a video to explain your competition</p>
              <div
                className="popup-video-drop-zone"
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onClick={handleDropZoneClick}
              >
                {!videoFile && <p>Drag and drop a video file here, or click to select one</p>}
                <input
                  type="file"
                  className="file-input"
                  accept="video/mp4"
                  onChange={handleFileChange}
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                />
                {videoFile && !uploadProgress && <p>Selected video: {videoFile.name}</p>}
                {uploadProgress ? (
                  <div className="upload-progress">
                    <span>Uploading {videoFile.name}:</span> <b>{uploadProgress}%</b>
                  </div>
                ) : null}
              </div>
              {!uploadProgress ? (
                <form onSubmit={handleCreateCompetitionSubmit}>
                  <label htmlFor="competition-name">Competition Name:</label>
                  <input
                    type="text"
                    id="competition-name"
                    value={competitionName}
                    onChange={handleCompetitionNameInputChange}
                    required
                  />
                  <label htmlFor="competition-description">Description:</label>
                  <textarea
                    id="competition-description"
                    value={competitionDescription}
                    onChange={handleCompetitionDescriptionInputChange}
                    required
                  />
                  <label htmlFor="competition-rules">Rules:</label>
                  <textarea
                    id="competition-rules"
                    value={competitionRules}
                    onChange={handleCompetitionRulesInputChange}
                    required
                  />
                  <div className="date-range-picker">
                    <div className="date-picker-section">
                      <label>Start Date</label>
                      <DatePicker
                        selected={startDate}
                        onChange={date => setStartDate(date)}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        placeholderText="Start Date"
                      />
                    </div>
                    <div className="date-picker-section">
                      <label>Deadline</label>
                      <DatePicker
                        selected={deadlineDate}
                        onChange={date => setDeadlineDate(date)}
                        startDate={deadlineDate}
                        endDate={deadlineDate}
                        placeholderText="Deadline"
                      />
                    </div>
                    <div className="date-picker-section">
                      <label>End Date</label>
                      <DatePicker
                        selected={endDate}
                        onChange={date => setEndDate(date)}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                        placeholderText="End Date"
                      />
                    </div>
                  </div>
                  <label htmlFor="competition-rules">Prizes:</label>
                  <CreatePrizeList handlePrizesChange={handlePrizesChange} />
                  <button type="submit">{isLoading ? "Loading" : "Create Competition"}</button>
                </form>
              ) : null}
            </Popup>
          )}
        </div>
      )}
      <br />
      <h2>Your Competitions</h2>
      {!isLoading ? <ListCompetitions /> : null}
    </div>
  );
}

export default Home;
